<template>
  <v-container class="page-container" fill-height fluid grid-list-xl>
    <v-layout wrap id="configuration" >
      <template v-if="loaded">
        <data-table ref="datatable"
                    :key="uniqueKey"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Draft Payouts & Invoices'"
                    :table-description="'Settling provider payments between therapists and My Online Therapy'"
                    :isDetail="false"
                    :isExport="true"
                    :exportUrl="'drafts'"
                    exportBtnText="Download"
                    :isCSVEmail="true"
                    @CsvEmail="showFinaliseDraftModal"
                    :is-custom-filter="false"
                    :pre-selected-filters="filters.selectedFilters"
                    :showGrayHeader="true"
        >
          <template slot="top-header">
            <div class="feature-toggle flex-display justify-end">
              <div class="toggle-switch">
                <label class="switch-label">Enable Therapist App Payout &nbsp;</label>
                <label class="switch">
                  <input type="checkbox" name="is_30_min_session_allowed" v-model="enablePayouts" :true-value="true" :false-value="false" v-on:change="togglePayouts">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </template>
          <template slot="header">
            <div class="w-100 flex-display flex-row justify-space-between mb-3">
              <div class="flex-display flex-column justify-start mt-3">
                <p class="m-b-20 fs-16 lh-24 grey-700">Owed to therapist</p>
                <h1 class="blue-500 fs-48 bold-font">£{{ owed_to_therapist }}</h1>
              </div>
              <div class="flex-display flex-column justify-start mt-3">
                <p class="grey-700 fs-16 lh-28">Owed to MOT</p>
                <h1 class="blue-500 fs-48 bold-font">£{{ owed_to_mot }}</h1>
              </div>
              <div class="flex-display flex-column justify-start mt-3">
              </div>
              <div class="flex-display flex-column justify-start">
                <div class="flex-display flex-row justify-space-between align-center m-b-10">
                  <label class="item-label col-5 m-0 p-0" for="billDate">Finalise date: </label>
                  <date-picker class="p-0"
                               id="billDate"
                               :date="finalize_date"
                               :org="true"
                               :isMaxDateEnable="false"
                               placeholder=""
                               v-on:valueUpdated="updateFinalizeDate"
                               v-on:saveDate="updateFinalizeDate"
                  ></date-picker>
                </div>
                <div class="flex-display flex-row justify-space-between align-center m-b-10">
                  <label class="item-label col-5 m-0 p-0" for="dueDate">Pay run date: </label>
                  <date-picker class="p-0"
                               id="dueDate"
                               :date="pay_run_date"
                               :org="true"
                               :isMaxDateEnable="false"
                               placeholder=""
                               v-on:valueUpdated="updatePayRunDate"
                               v-on:saveDate="updatePayRunDate"
                  ></date-picker>
                </div>
                <div class="flex-display flex-row justify-space-between align-center">
                  <label class="item-label col-5 m-0 p-0 no-wrap" for="paymentTerm">Payment terms: </label>
                  <input type="number" class="input-text" name="payment_term" id="paymentTerm"
                         v-model="payment_term">
                  <img class="pointer right-67 position-absolute" width="18" height="18" alt="checkMark" src="/icons/check_mark.png" @click="updatePaymentTerm"/>
                </div>
              </div>

            </div>
          </template>
          <template slot="filters">
            <multi-select-filters :filter-id="'therapist_filter'"
                                  :filters="filters.therapistFilter"
                                  :placeholder="'Therapist'"
                                  :column="'therapist_id'"
                                  :operator="'in'"
            ></multi-select-filters>
          </template>
        </data-table>
      </template>
      <!-- Import CSV -->
      <finalise-draft-item v-if="finaliseDraftDialog"
                  @submitFinalizeDraft="finalizeDraft"
                  :visible="finaliseDraftDialog"
                  v-on:hide="hideFinaliseDraftModal"
      >
      </finalise-draft-item>

    </v-layout>
  </v-container>
</template>

<script>

import DataTable from "@/components/common/dataTable/DataTable";
import Filters from "@/components/common/dataTable/filters/Filters";
import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";
import DateRangeFilter from "@/components/common/dataTable/filters/DateRangeFilter";
import Tabs from "@/components/common/dataTable/tabs";
import DatePicker from "../components/common/DatePicker";
import FinaliseDraftItem from "@/components/main/FinaliseDraft";

export default {
  name: "drafts",
  components: {
    FinaliseDraftItem,
    Tabs,
    Filters,
    DataTable,
    MultiSelectFilters,
    DateRangeFilter,
    DatePicker
  },
  data(){
    return {
      apiurl: 'drafts',
      uniqueKey: 'drafts',
      search: {
        placeholder: 'Search…',
        searchAble: true
      },
      sortOrder: [
        {
          field: 'therapist.full_name',
          sortField: 'therapist_first_name',
          direction: 'asc'
        }
      ],
      filters: null,
      loaded: true,
      finalize_date: '',
      pay_run_date: '',
      payment_term: null,
      owed_to_therapist: null,
      owed_to_mot: null,
      finaliseDraftDialog: false,
      pay_run_id: false,
      enablePayouts: false

    }
  },
  created() {
    this.fetchFilters();
    this.getPayoutsFeature();
  },
  methods: {
    fetchFilters() {
      this.$http.get(this.url + 'filters/drafts').then((res) => {
        if (res.data) {
          this.filters = res.data;
          //this.fetchTabs();
          this.fetchPayrunRecord();
        } else {
          alert('there is some problem');
        }
      });
    },
    updateFinalizeDate(date , updateApiCheck = false) {
      this.finalize_date = date;
      if(updateApiCheck) {
        this.updatePayRunDetails('finalize_date', this.finalize_date);
      }

    },
    updatePayRunDate(date ,updateApiCheck = false) {
      this.pay_run_date = date;
      if(updateApiCheck) {
        this.updatePayRunDetails('pay_run_date', this.pay_run_date);
      }
    },
    updatePaymentTerm() {
        this.updatePayRunDetails('payment_term', this.payment_term);
    },
    finalizeDraft(finalizeDate, payRunDate) {
      let loader = this.$loading.show()
      let formData = {
        finalise_date: finalizeDate,
        pay_run_date: payRunDate,
        payment_term: this.payment_term,
      }
      this.$http.put(this.url + 'draft/finalize', formData).then((response) => {
        this.hideFinaliseDraftModal();
        this.$events.fire('vuetable:reload');
        this.fetchPayrunRecord();
        loader.hide();
        this.$toastr('success', response.data.message, '');
      }).catch((error) => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },
    fetchPayrunRecord(){
      this.$http.get(this.url+'draft/payrun_record').then((res) => {
        if(res.data) {
          this.pay_run_id = res.data.pay_run_id;
          this.pay_run_date = res.data.pay_run_date;
          this.finalize_date = res.data.finalize_date;
          this.payment_term = res.data.payment_term;
          this.owed_to_therapist = res.data.owed_to_therapist;
          this.owed_to_mot = res.data.owed_to_mot;
        } else {
          alert('there is some problem');
        }
      });
    },
    updatePayRunDetails(colName,data){
      let formData = {};
      let loader = this.$loading.show()
      formData = {
        pay_run_id: this.pay_run_id,
        column_name: colName,
        value : data,
        device_type: "web"
      };
      this.$http.put(this.url + "payrun_record/update", formData).then((response) => {
        loader.hide();
        this.$toastr('add', {
          msg: response.data.message, // Message
          timeout: 8000, // Timeout in ms
          type: 'success', // Toastr type
        });
        this.fetchPayrunRecord();
      }).catch((error) => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "");
      });
    },
    showFinaliseDraftModal() {
      this.finaliseDraftDialog = true;
    },
    hideFinaliseDraftModal() {
      this.finaliseDraftDialog = false;
    },
    getPayoutsFeature() {
      let self = this;
      let loader = this.$loading.show();
      this.$http.get(this.url+'features/payouts', {
      }).then(function (response) {
        loader.hide();
        self.enablePayouts = response.data.is_active;
      }).catch(function (error) {
        loader.hide();
      })
    },
    togglePayouts() {
      let self = this;
      let loader = this.$loading.show();
      this.$http.put(this.url + 'features/payouts/toggle', {
        status: self.enablePayouts
      }).then(function (response) {
        loader.hide();
      }).catch(function (error) {
        loader.hide();
      });
    }
  },
  events: {
    'finalise-draft-item': function() {
      this.finaliseDraftDialog = true;
      this.showFinaliseDraftModal();
    },

  },
  watch: {
    // 'payment_term': function () {
    //   this.updatePayRunDetails('payment_term', this.payment_term);
    // },
  },
}
</script>

<style scoped>
.right-67{
  right: 67px;
}
</style>
